import React, {useState} from 'react';
import classNames from "classnames";

import green_wave from './../../assets/img/green_wave.png';
import purple_bottle from './../../assets/img/purple_bottle.png';
import blueberries_vitamin from './../../assets/img/blueberries_vitamin.png';
import crazy_kent_green_block from './../../assets/img/crazy_kent_green_block.png';
import blueberry_coconut_assai_text from './../../assets/img/blueberry_coconut_assai_text.png';
import union_green from './../../assets/img/union_green.png';
import green_spray from './../../assets/img/green_spray.png'
import green_wave_mobile from './../../assets/img/green_wave.png'
import union_orange_mobile from './../../assets/img/union_orange_mobile.png'

import s from './GreenBlock.module.scss';
import {useInView} from "react-intersection-observer";

const GreenBlock = () => {
    const { ref, inView } = useInView({
        threshold: 0.2,
        //triggerOnce: true,
    });
    const { ref: refMobile, inView: inViewMobile } = useInView({
        threshold: 0.2,
        //triggerOnce: true,
    });
    return (
        <div className={s.wrapper}>
            <img className={s.green_wave} src={green_wave} alt="green wave"/>
            <img className={s.green_wave_mobile} src={green_wave_mobile} alt="green wave"/>
            <img className={s.blueberries_vitamin} src={blueberries_vitamin} alt="черника и витамин B"/>
            <img className={s.purple_bottle} src={purple_bottle} alt="черника кокос асаи бутылка"/>
        
            <img className={s.blueberry_coconut_assai_text} src={blueberry_coconut_assai_text} alt="черника кокос асаи"/>
            {/* <img className={s.berry} src={cirkle} alt="ягода"/> */}
            <img className={s.green_spray} src={green_spray} alt="зелёная брызга"/>
            <img ref={ref} className={classNames(s.union, {
                [s.union_show]: inView,
            })} src={union_green}
                 alt="личи смиотрит на бутылку"/>
            <img
                className={classNames(s.crazy_kent_green_block, {
                [s.linch_show]: inView,
                [s.linch_animation]: inView,
            })} src={crazy_kent_green_block}
                 alt="crazy линч"/>

            <img ref={refMobile}  className={classNames(s.union_orange_mobile, {
                [s.union_orange_mobile_show]: inViewMobile,
            })} src={union_orange_mobile} alt="crazy линч"/>

        </div>
    )
}

export default GreenBlock;