import React, {useState} from 'react';
import classNames from "classnames";

import green_bottle from './../../assets/img/green_bottle.png';
import bottle_mobile from './../../assets/img/yellow_bottle_mobile.png';

import crazy_kent_purple from './../../assets/img/crazy_kent_purple.png';
import energy_left from './../../assets/img/energy_left.png';

import union_purple from './../../assets/img/union_purple.png';
import coffe from './../../assets/img/coffe.png';
import kiwi_banana_lime_text from './../../assets/img/kiwi_banana_lime_text.png';
import lime_energy from './../../assets/img/lime_energy.png';

import union_eat_mobile from './../../assets/img/union_eat_mobile.png';
import yellow_mobile_text from './../../assets/img/yellow_mobile_text.png';

import s from './PurpleBlock.module.scss';
import {useInView} from "react-intersection-observer";

const PurpleBlock = () => {
    const { ref, inView } = useInView({
        threshold: 0.2,
    });
    const { ref: refMobile, inView:inViewMobile } = useInView({
        threshold: 0.2,
    });

    return <>
        <div className={s.wrapper}>
            <img className={s.green_bottle} src={green_bottle} alt="crazy lazy energy бутылка с киви, бананом и лаймом"/>
            <img ref={ref} className={classNames(s.union_purple, {
                [s.union_show]: inView,
                [s.union_animation]: inView,
            })} src={union_purple} alt="мысли лейзи"/>
            <img className={s.kiwi_banana_lime_text} src={kiwi_banana_lime_text} alt="киви банан лайм"/>
            <img className={s.energy_left} src={energy_left} alt="energy"/>
            <img className={s.crazy_kent_purple} src={crazy_kent_purple} alt="carzy lich и фрукты"/>
            <img className={s.coffe} src={coffe} alt="кофеин"/>
            <img className={s.bottle_mobile} src={bottle_mobile} alt="crazy lazy energy бутылка с дыней и чизкейком"/>
            
            <img className={s.lime_energy} src={lime_energy} alt="лайм и energy"/>
            <img ref={refMobile} className={classNames(s.union_eat_mobile, {
                [s.union_eat_mobile_show]: inViewMobile
            })} src={union_eat_mobile} alt="мысли"/>
            <img className={s.yellow_mobile_text} src={yellow_mobile_text} alt="дыня и чизкейк"/>

            {/*<img className={s.bottle_mobile} src={bottle_mobile} alt="crazy lazy energy бутылка с дыней и чизкейком"/>
            <img className={s.spray_left} src={orange_spray_left} alt="брызга"/>
            <img className={s.spray_right} src={orange_spray_right} alt="брызга"/>
            <img className={s.melon_half} src={melon_half} alt="дыня"/>
            <img className={s.melon_2} src={melon} alt="дыня"/>
            <img
                ref={ref}
                className={classNames(s.lazy_people, {
                    [s.lazy_show]: inView,
                    [s.lazy_animation]: inView,
                })}
                src={lazy_orange_people}
                alt="лейзи кушает"
            />
            <img className={s.melon_text} src={melon_and_chizecake} alt="дыня и чизкейк"/>
            <img className={classNames(s.union_eat, {
                [s.union_show]: inView,
                [s.union_animation]: inView,
            })} src={union_eat} alt="мысли лейзи"/>
            <img className={s.cofe} src={cofe_green} alt="кофеин"/>*/}



        </div>
    </>
}

export default PurpleBlock;