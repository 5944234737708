import React from 'react';

import instagram_icon from './../../assets/img/instagram_icon.png';
import goverement_number from './../../assets/img/goverement_number.png';
import gov_number_mobile from './../../assets/img/gov_number_mobile.png';
import tiktok from './../../assets/img/tiktok.png';

import s from './footer.module.scss';
import {LINKS} from "../../constants";

const Footer = () => {
    return <footer className={s.footer}>
        <div>

        <div className={s.first_line}>
            Подпишись на наши соцсеточки:
            <a target="_blank" href={LINKS.INSTAGRAM}>

                <div className={s.inst_icon}>
                    <svg className={s.star_instagram} width="55" height="55" viewBox="0 0 55 55" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M27.5 0L29.4495 3.35106L31.9113 0.356117L33.298 3.97651L36.2084 1.41525L36.9964 5.2112L40.2799 3.14996L40.4488 7.02316L44.0204 5.51532L43.5658 9.36546L47.3331 8.45008L46.2667 12.1774L50.1321 11.8782L48.4816 15.3863L52.3449 15.711L50.1531 18.9088L53.9142 19.849L51.2379 22.6539L54.7995 24.1852L51.7079 26.5245L54.9777 28.6073L51.5509 30.4203L54.4442 33.0007L50.771 34.2405L53.2129 37.2516L49.3883 37.8861L51.3157 41.25L47.4388 41.2628L48.8016 44.8923L44.9729 44.283L45.7359 48.084L42.0545 46.8685L42.1978 50.7427L38.7591 48.9524L38.2791 52.7994L35.1721 50.4807L34.0812 54.2009L31.3864 51.4138L29.7128 54.9108L27.5 51.7275L25.2872 54.9108L23.6136 51.4138L20.9188 54.2009L19.8279 50.4807L16.7209 52.7994L16.2409 48.9524L12.8022 50.7427L12.9455 46.8685L9.26413 48.084L10.0271 44.283L6.19836 44.8923L7.56116 41.2628L3.6843 41.25L5.61165 37.8861L1.78705 37.2516L4.22905 34.2405L0.555758 33.0007L3.44915 30.4203L0.0223026 28.6073L3.29215 26.5245L0.200506 24.1852L3.76212 22.6539L1.08575 19.849L4.8469 18.9088L2.65511 15.711L6.51837 15.3863L4.86794 11.8782L8.73326 12.1774L7.66693 8.45008L11.4342 9.36546L10.9796 5.51532L14.5512 7.02316L14.7201 3.14996L18.0036 5.2112L18.7916 1.41525L21.702 3.97651L23.0887 0.356117L25.5505 3.35106L27.5 0Z"
                            fill="#EB627E"/>
                    </svg>
                    <img className={s.inst} src={instagram_icon} alt="instagram"/>
                </div>
            </a>
            <a target="_blank" href={LINKS.TIKTOK}>

                <div className={s.inst_icon}>
                    <svg className={s.star_instagram} width="55" height="55" viewBox="0 0 55 55" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M27.5 0L29.4495 3.35106L31.9113 0.356117L33.298 3.97651L36.2084 1.41525L36.9964 5.2112L40.2799 3.14996L40.4488 7.02316L44.0204 5.51532L43.5658 9.36546L47.3331 8.45008L46.2667 12.1774L50.1321 11.8782L48.4816 15.3863L52.3449 15.711L50.1531 18.9088L53.9142 19.849L51.2379 22.6539L54.7995 24.1852L51.7079 26.5245L54.9777 28.6073L51.5509 30.4203L54.4442 33.0007L50.771 34.2405L53.2129 37.2516L49.3883 37.8861L51.3157 41.25L47.4388 41.2628L48.8016 44.8923L44.9729 44.283L45.7359 48.084L42.0545 46.8685L42.1978 50.7427L38.7591 48.9524L38.2791 52.7994L35.1721 50.4807L34.0812 54.2009L31.3864 51.4138L29.7128 54.9108L27.5 51.7275L25.2872 54.9108L23.6136 51.4138L20.9188 54.2009L19.8279 50.4807L16.7209 52.7994L16.2409 48.9524L12.8022 50.7427L12.9455 46.8685L9.26413 48.084L10.0271 44.283L6.19836 44.8923L7.56116 41.2628L3.6843 41.25L5.61165 37.8861L1.78705 37.2516L4.22905 34.2405L0.555758 33.0007L3.44915 30.4203L0.0223026 28.6073L3.29215 26.5245L0.200506 24.1852L3.76212 22.6539L1.08575 19.849L4.8469 18.9088L2.65511 15.711L6.51837 15.3863L4.86794 11.8782L8.73326 12.1774L7.66693 8.45008L11.4342 9.36546L10.9796 5.51532L14.5512 7.02316L14.7201 3.14996L18.0036 5.2112L18.7916 1.41525L21.702 3.97651L23.0887 0.356117L25.5505 3.35106L27.5 0Z"
                            fill="#EB627E"/>
                    </svg>
                    <img className={s.tik} src={tiktok} alt="tiktok"/>
                </div>
            </a>

        </div>
        <div className={s.second_line}>А если ты из прошлого века, то вот email:  <a
            href={`mailto:${LINKS.MAIL}`}> {LINKS.MAIL}</a></div>
        </div>
        <div>
            <img className={s.gov_number} src={goverement_number} alt=""/>
            <img className={s.gov_number_mobile} src={gov_number_mobile} alt=""/>
        </div>
    </footer>
}

export default Footer;